export default defineNuxtRouteMiddleware(async (to, from) => {
  const nuxtApp = useNuxtApp()
  const oryInstance: any = useOry()
  const { flow: flowIdRef, id, error } = to.query as any
  const { kratosFlowId, setFlowId, setKratosFlow } = useFlowOry()
  const previousPath = useCookie('previousPath')

  let flow = null

  const whiteList = ['login', 'recovery', 'verification', 'failed']

  if (from.path?.includes('welcome')) {
    setFlowId(null)
  }

  if (!whiteList.some(path => to.path?.includes(path))) {
    kratosFlowId.value = flowIdRef ?? kratosFlowId.value
    flow = kratosFlowId.value
  } else {
    flow = flowIdRef ?? kratosFlowId.value
  }

  try {
    if (!flow && !id) {
      return
    }

    if (!id) {
      let cookie
      if (process.server) {
        cookie = useRequestHeaders(['cookie'])?.cookie
      }

      if (nuxtApp?._route?.path?.includes('login') && flow) {
        const kratosFlow = (await oryInstance.getLoginFlow({ id: flow, cookie })).data
        setKratosFlow(kratosFlow)
      } else if (nuxtApp?._route?.path?.includes('recovery') && flow) {
        const kratosFlow = (await oryInstance.getRecoveryFlow({ id: flow, cookie: cookie })).data
        setKratosFlow(kratosFlow)
      } else if (nuxtApp?._route?.path?.includes('verification') && flow) {
        const kratosFlow = (await oryInstance.getVerificationFlow({ id: flow, cookie })).data
        setKratosFlow(kratosFlow)
      } else if (nuxtApp?._route?.path?.includes('configuration') && flow) {
        const kratosFlow = (await oryInstance.getSettingsFlow({ id: flow, cookie })).data
        setKratosFlow(kratosFlow)

        // refirect to login if the flow change password is sueccess
        const { userAuthOryData } = useUserAuthOry()
        const messageId = kratosFlow?.ui?.messages?.length > 0 ? kratosFlow.ui.messages[0].id : 0;
        if (messageId === 1050001 && isLinkRecoveryMethod(userAuthOryData.value)) {
          const result = (await oryInstance.createBrowserLogoutFlow({ cookie })).data
          return navigateTo(result?.logout_url, { external: true })
        }

        // redirect to config password if the flow change password
        if (kratosFlow?.return_to && !kratosFlow?.return_to.includes(to.path)) {
          setFlowId(kratosFlow?.id)
          const path = kratosFlow?.return_to
          return navigateTo(path, { external: true })
        }

        if (previousPath.value && previousPath.value !== to.path) {
          return navigateTo(previousPath.value)
        }
      }

      return
    }

    if (nuxtApp?._route?.path?.includes('failed')) {
      setFlowId(null)
      nuxtApp.kratosError = (await oryInstance.getFlowError({ id: id ?? error })).data
    }
  } catch (error: any) {
    useBugsnag().notify(error)
    setFlowId(null)
    throw createError({
      statusCode: error?.response?.status,
      statusMessage: 'Error al obtener el flujo de autenticación.',
      message: error?.response?.data?.error?.message,
    })
  }
})
