<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps({
  error: Object as () => NuxtError
})

const config = useRuntimeConfig()

useHead({
  title: `${config.public.titleApp} | Error ${props?.error?.statusCode}`
})

// Extract the error message before : from the error message
const statusMessage = props?.error?.statusMessage?.split(':')[0] ?? props?.error?.statusMessage

</script>
<template>
  <div class="grid min-h-screen grid-cols-1 grid-rows-[1fr,auto,1fr] bg-white lg:grid-cols-[max(50%,36rem),1fr]">
    <header class="w-full px-6 pt-6 mx-auto max-w-7xl sm:pt-10 lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:px-8">
      <a href="#">
        <span class="sr-only">rem tools</span>
        <img class="w-auto h-20" src="~/assets/logo.svg" alt="rem.rools" />
      </a>
    </header>
    <main class="w-full px-6 py-24 mx-auto max-w-7xl sm:py-32 lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:px-8">
      <div class="max-w-lg">
        <!-- <p class="text-3xl font-semibold leading-8 text-primary">{{ props?.error?.statusCode }}</p> -->
        <p class="text-3xl font-semibold leading-8 text-primary">{{ props?.error?.url ?? props?.error?.statusCode }}</p>
        <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">{{ statusMessage ?? 'Página no encontrada.'}}</h1>
        <p class="mt-6 text-base leading-7 text-gray-600">{{ error?.message }}</p>
        <!-- {{ error }} -->
        <div class="mt-10">
          <NuxtLink to="/welcome" class="rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary">Regresar a Inicio</NuxtLink>
        </div>
      </div>
    </main>
    <footer class="self-end lg:col-span-2 lg:col-start-1 lg:row-start-3">
      <div class="py-10 border-t border-gray-100 bg-gray-50">
        <nav class="flex items-center w-full px-6 mx-auto text-sm leading-7 text-gray-600 max-w-7xl gap-x-4 lg:px-8">
          <a href="mailto:jorge@rem.tools">Contactar soporte</a>
        </nav>
      </div>
    </footer>
    <div class="hidden lg:relative lg:col-start-2 lg:row-start-1 lg:row-end-4 lg:block">
      <img src="https://images.unsplash.com/photo-1470847355775-e0e3c35a9a2c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1825&q=80" alt="image rem" class="absolute inset-0 object-cover w-full h-full" />
    </div>
  </div>
</template>
