<script setup lang="ts">
import { ref, computed } from 'vue';
import { XMarkIcon } from '@heroicons/vue/20/solid'

const router = useRouter()

interface Props {
  show: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  show: false,
})

const showBanner = ref(props.show);

const shouldShowBanner = computed(() => showBanner.value);

function hideBanner() {
  showBanner.value = false;
}



const handleVerifyEmail = async () => {
  await router.replace(`/auth/verification`)
}


</script>
<template>
  <Transition name="fade" class="flex">
    <div v-if="shouldShowBanner" class="flex items-center w-full justify-center gap-x-6 bg-yellow-50 px-6 py-2.5 sm:px-3.5">
      <p class="text-sm leading-6 text-yellow-800 mx-auto">
        <div>
          <strong class="font-semibold">¡Verifica tu cuenta!</strong>
          <svg viewBox="0 0 2 2" class="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
            <circle cx="1" cy="1" r="1" />
          </svg>
          Haz clic en el botón <i>Generar</i> para recibir tu enlace de verificación.
          <span @click="() => handleVerifyEmail()" class="flex-none cursor-pointer rounded-full bg-yellow-100 px-3.5 py-1 text-sm font-semibold text-yellow-800 shadow-sm hover:bg-yellow-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">Generar <span aria-hidden="true">&rarr;</span></span>
        </div>
      </p>
      <div class="float-right">
        <button type="button" class="-m-3 p-3 focus-visible:outline-offset-[-4px]" @click="hideBanner">
          <span class="sr-only">Dismiss</span>
          <XMarkIcon class="h-5 w-5 text-yellow-800" aria-hidden="true" />
        </button>
      </div>
    </div>
  </Transition>
</template>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
