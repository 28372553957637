<script setup lang="ts">
import { useRuntimeConfig } from '#app'
import { useHead } from '@vueuse/head'
import { ref, computed } from 'vue'
import { get } from '@vueuse/core'
import stringToColor from 'string-to-color'
import { KeyIcon, LockClosedIcon, UserIcon } from '@heroicons/vue/24/outline'
import loadingState from '@/composables/useLoading';
import { sidebarNavigation, sidebarNavigationFooter } from '@/routes'
import { useUserAuth } from '#imports'


const config = useRuntimeConfig()

const accountUrl = config.public.accountUrl
const mobileMenuOpen = ref(false)
const title =  config.public.titleApp
const version = 'v' + config.public.version

const { userAuthOryData } = useUserAuthOry()
const { userAuthData } = useUserAuth()

const filterByRole = (items: any[]) => {
  if (config.public.developmentWithOry === 'true') {
    return items;
  }
  return items.filter((item) => {
    if (!item.hasOwnProperty('role')) {
      return true;
    }
    return userAuthData.value?.user?.role === item?.role || item?.role === 'user';
  });
}

const sidebarNavigationProtect = computed(() => filterByRole(sidebarNavigation));
const sidebarNavigationFooterProtect = computed(() => filterByRole(sidebarNavigationFooter));

const logoutUrl = useLogout()




const profileColor = stringToColor(get(userAuthData)?.user?.name ?? 'default')
const profileInitials = getInitials(get(userAuthData)?.user?.name ?? 'User')



const navigation = [
  { name: 'Perfil', href: '/configuration/profile', icon: UserIcon },
  { name: 'Contraseña', href: '/configuration/password', icon: LockClosedIcon },
  { name: '2FA', href: '/configuration/2fa', icon: KeyIcon }
]

const loading = loadingState()

useHead({
  bodyAttrs: {
    class: 'h-full'
  },
  htmlAttrs: {
    class: 'h-full bg-gray-100'
  },
  title
})
</script>
<template>
  <RemLayout @open="mobileMenuOpen = true">
      <template #sidebar>
        <RemNarrowSidebar
            v-if="sidebarNavigationProtect.length"
            :sidebarNavigation="sidebarNavigationProtect"
            :version="version"
            :title="title"
            :mobileMenuOpen="mobileMenuOpen"
            :sidebarNavigationFooter="sidebarNavigationFooterProtect"
            @close="mobileMenuOpen = false"
        />
      </template>

      <template #header>
        <div class="w-full my-auto">
        </div>
      </template>
      <template #profileMenu>
          <RemUserMenu
            :profileInitials="profileInitials"
            :profileColor="profileColor"
            :items="[
              { label: 'Inicio', href: accountUrl + '/welcome' },
              { label: 'Configuración', href: accountUrl + '/configuration/profile' },
              { label: 'Salir', href: logoutUrl }
            ]"
          />
      </template>
      <!-- container ml-[8.9%] mr-[10%] -->
      <!-- mx-8 sm:mx-15 md:mx-12 lg:mx-32 -->
      <template #subMenu>
        <div v-if="($router.currentRoute.value.path.startsWith('/configuration/') && !loading) || ($router.options?.history?.state?.back?.startsWith('/configuration') && $router.currentRoute.value?.path?.startsWith('/configuration'))" class="flex w-full shadow bg-white">
          <div class="container flex flex-col">
            <div class="px-2 sm:px-6">
              <div class="flex flex-row w-full py-2">
                <NuxtLink
                  v-for="item in navigation" :key="item.name"
                  :disabled="item.href === $route.path"
                  :to="item.href || $route.path"
                  :class="[(item.href === '/' ? $route.path === item.href : $route.path.includes(item.href) && $route.path.startsWith(item.href)) ? 'bg-gray-100 py-2 text-gray-700 font-semibold' : 'bg-white text-gray-500 hover:text-gray-700', 'py-2 px-3 rounded-md text-sm font-medium']"
                >
                <div class="flex flex-row items-center">
                  <component :is="item?.icon" class="h-4 w-5 mr-1" aria-hidden="true" />
                  <span>{{ item.name }}</span>
                </div>
                </NuxtLink>
              </div>
            </div>
          </div>
        </div>
      </template>
      <ClientOnly>
        <template v-if="userAuthOryData?.identity">
          <Banner :show="!userAuthOryData?.identity?.verifiable_addresses[0]?.verified" />
        </template>
      </ClientOnly>
      <!-- Main content -->
      <slot />
  </RemLayout>
</template>
