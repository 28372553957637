<script setup lang="ts">
import { getNodeLabel } from '@ory/integrations/ui'

definePageMeta({
  layout: 'auth',
})

const config = useRuntimeConfig()
useHead({
  title: `${config.public.titleApp} | Iniciar sesión`
})

// const nuxtApp = useNuxtApp()
const router = useRouter()
const oryInstance = useOry()
const isLoading = ref<boolean>(false)

const { kratosFlow, setKratosFlow } = useFlowOry()
const flow = ref<any>(kratosFlow.value ?? null)

onMounted(async () => {
  if (!flow.value){
    isLoading.value = true
    await oryInstance.createBrowserLoginFlow()
      .then(({ data }: any) => {
        flow.value = data
        router.replace(`/auth/login?flow=${data.id}`)
      })
      .catch((err: any) => {
        isLoading.value = false
        if (err?.response?.data?.error?.code === 400) {
          router.replace('/welcome')
        } else {
          useBugsnag().notify(err)
          throw createError({
            statusCode: 500,
            message: 'Unable to create login flow',
            statusMessage: err?.response?.data?.error?.message ?? 'Unknown error',
          })
        }
      }).finally(() => {
        isLoading.value = false
      })
  }
})

const fields = computed(() => {
  if (flow.value) {
    return flow.value?.ui?.nodes ?? []
  }
  return []
})

const messages = computed(() => {
  if (flow.value) {
    return flow.value?.ui?.messages ?? []
  }
  return []
})

const formatLabel = (label: string) => {
  if (!label) {
    return ''
  }
  return label.charAt(0).toUpperCase() + label.slice(1)
}

const goToRecovery = () => {
  setKratosFlow(null)
  router.push('/auth/recovery')
}


</script>
<template>
  <div>
    <div>
      <img class="w-auto h-20 mx-auto" src="~/assets/logo.svg" alt="Rem Tools" />
      <h2 class="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">Ingresa a tu cuenta</h2>
    </div>

    <div class="mt-10">
      <ErrorInfo v-if="messages" :messages="messages" />
      <div v-if="!isLoading && flow && fields?.length">
        <form class="space-y-6" :action="flow.ui.action" :method="flow.ui.method">
          <!-- Inputs -->
          <div
            v-for="(field, index) in fields"
            :key="index"
          >
            <label v-if="field.attributes.type !== 'submit'" :for="field.attributes.name" class="block text-sm font-medium leading-6 text-gray-900">{{ getNodeLabel(field) }}</label>
            <input
              v-if="field.attributes.type !== 'submit'"
              :id="field.attributes.name"
              :name="field.attributes.name"
              :type="field.attributes.type"
              :autocomplete="field.attributes.name"
              :required="field.attributes.required"
              :value="field.attributes.value"
              class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              :placeholder="formatLabel(field?.attributes?.name)"
            >
            <button
              v-else
              :id="field.attributes.name"
              :name="field.attributes.name"
              type="submit"
              :autocomplete="field.attributes.name"
              :required="field.attributes.required"
              :value="field.attributes.value"
              class="relative flex justify-center w-full px-4 py-2 mt-4 text-sm font-medium text-white border border-transparent rounded-md disabled:opacity-50 group bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            >
              Entrar
            </button>
          </div>

          <div class="flex items-center justify-end">
            <div class="text-sm">
              <span @click="goToRecovery" class="font-medium cursor-pointer text-primary hover:text-primary/90">
                ¿Olvidaste tu contraseña?
              </span>
            </div>
          </div>
          <!-- End inputs -->
        </form>
      </div>
      <div v-else class="pb-10 space-y-5">
        <SkeletonInput>
          <template #label>
            Correo
          </template>
        </SkeletonInput>
        <SkeletonInput>
          <template #label>
            Password
          </template>
        </SkeletonInput>
        <SkeletonButton>
          <template #label>
            Entrar
          </template>
        </SkeletonButton>
      </div>
    </div>
  </div>
</template>
