<script setup lang="ts">

</script>
<template>
  <div>
    <div class="flex flex-col space-y-1">
      <label for="mi-input" class="text-gray-700 text-sm font-medium">
        <slot name="label"></slot>
      </label>
      <div class="animate-pulse">
        <div class="bg-gray-200 h-10 rounded"></div>
      </div>
    </div>
  </div>
</template>