import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45validate_45session_45global from "/vercel/path0/middleware/01.validate-session.global.ts";
import _02_45validate_45flow_45global from "/vercel/path0/middleware/02.validate-flow.global.ts";
import validate_45type_45session from "/vercel/path0/node_modules/@rem-tools/common-selfservice/dist/runtime/middleware/validate-type-session.global.mjs";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45validate_45session_45global,
  _02_45validate_45flow_45global,
  validate_45type_45session,
  manifest_45route_45rule
]
export const namedMiddleware = {}