import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import notifications_JEGI6HEtmk from "/vercel/path0/node_modules/@rem-tools/common-selfservice/dist/runtime/plugins/notifications.mjs";
import plugin_WS3W3h3Aix from "/vercel/path0/node_modules/@rem-tools/nuxt-ory/dist/runtime/plugin.mjs";
import plugin_w2VlvAEcpa from "/vercel/path0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_BqLkNUef68 from "/vercel/path0/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import bugsnag_client_TtQ636hzGt from "/vercel/path0/plugins/bugsnag.client.ts";
import qrcode_vue_client_f8DK1qE180 from "/vercel/path0/plugins/qrcode-vue.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  notifications_JEGI6HEtmk,
  plugin_WS3W3h3Aix,
  plugin_w2VlvAEcpa,
  plugin_BqLkNUef68,
  chunk_reload_client_UciE0i6zes,
  bugsnag_client_TtQ636hzGt,
  qrcode_vue_client_f8DK1qE180
]