import { useState } from '#app'

const loadingState = () => {
  return useState<boolean>('loading', () => false)
}

export const setLoading = (value: boolean) => {
  const state = loadingState()
  state.value = value
}

export default loadingState
