export default [
  "./components/**/*.{js,vue,ts}",
  "./layouts/**/*.vue",
  "./pages/**/*.vue",
  "./plugins/**/*.{js,ts}",
  "./nuxt.config.{js,ts}",
  "./node_modules/@rem-tools/common-selfservice/dist/runtime/components/**/*.vue",
  "./node_modules/@rem-tools/common-selfservice/dist/runtime/layouts/**/*.vue",
  "../nuxt-common-selfservice/dist/runtime/components/**/*.vue",
  "../nuxt-common-selfservice/dist/runtime/layouts/**/*.vue",
  "/vercel/path0/components/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/vercel/path0/components/global/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/vercel/path0/components/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/vercel/path0/layouts/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/vercel/path0/pages/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/vercel/path0/plugins/**/*.{js,ts,mjs}",
  "/vercel/path0/composables/**/*.{js,ts,mjs}",
  "/vercel/path0/utils/**/*.{js,ts,mjs}",
  "/vercel/path0/{A,a}pp.{vue,js,jsx,mjs,ts,tsx}",
  "/vercel/path0/{E,e}rror.{vue,js,jsx,mjs,ts,tsx}",
  "/vercel/path0/app.config.{js,ts,mjs}"
]