export default [
  "preflight",
  "container",
  "accessibility",
  "pointerEvents",
  "visibility",
  "position",
  "inset",
  "isolation",
  "zIndex",
  "order",
  "gridColumn",
  "gridColumnStart",
  "gridColumnEnd",
  "gridRow",
  "gridRowStart",
  "gridRowEnd",
  "float",
  "clear",
  "margin",
  "boxSizing",
  "lineClamp",
  "display",
  "aspectRatio",
  "size",
  "height",
  "maxHeight",
  "minHeight",
  "width",
  "minWidth",
  "maxWidth",
  "flex",
  "flexShrink",
  "flexGrow",
  "flexBasis",
  "tableLayout",
  "captionSide",
  "borderCollapse",
  "borderSpacing",
  "transformOrigin",
  "translate",
  "rotate",
  "skew",
  "scale",
  "transform",
  "animation",
  "cursor",
  "touchAction",
  "userSelect",
  "resize",
  "scrollSnapType",
  "scrollSnapAlign",
  "scrollSnapStop",
  "scrollMargin",
  "scrollPadding",
  "listStylePosition",
  "listStyleType",
  "listStyleImage",
  "appearance",
  "columns",
  "breakBefore",
  "breakInside",
  "breakAfter",
  "gridAutoColumns",
  "gridAutoFlow",
  "gridAutoRows",
  "gridTemplateColumns",
  "gridTemplateRows",
  "flexDirection",
  "flexWrap",
  "placeContent",
  "placeItems",
  "alignContent",
  "alignItems",
  "justifyContent",
  "justifyItems",
  "gap",
  "space",
  "divideWidth",
  "divideStyle",
  "divideColor",
  "divideOpacity",
  "placeSelf",
  "alignSelf",
  "justifySelf",
  "overflow",
  "overscrollBehavior",
  "scrollBehavior",
  "textOverflow",
  "hyphens",
  "whitespace",
  "textWrap",
  "wordBreak",
  "borderRadius",
  "borderWidth",
  "borderStyle",
  "borderColor",
  "borderOpacity",
  "backgroundColor",
  "backgroundOpacity",
  "backgroundImage",
  "gradientColorStops",
  "boxDecorationBreak",
  "backgroundSize",
  "backgroundAttachment",
  "backgroundClip",
  "backgroundPosition",
  "backgroundRepeat",
  "backgroundOrigin",
  "fill",
  "stroke",
  "strokeWidth",
  "objectFit",
  "objectPosition",
  "padding",
  "textAlign",
  "textIndent",
  "verticalAlign",
  "fontFamily",
  "fontSize",
  "fontWeight",
  "textTransform",
  "fontStyle",
  "fontVariantNumeric",
  "lineHeight",
  "letterSpacing",
  "textColor",
  "textOpacity",
  "textDecoration",
  "textDecorationColor",
  "textDecorationStyle",
  "textDecorationThickness",
  "textUnderlineOffset",
  "fontSmoothing",
  "placeholderColor",
  "placeholderOpacity",
  "caretColor",
  "accentColor",
  "opacity",
  "backgroundBlendMode",
  "mixBlendMode",
  "boxShadow",
  "boxShadowColor",
  "outlineStyle",
  "outlineWidth",
  "outlineOffset",
  "outlineColor",
  "ringWidth",
  "ringColor",
  "ringOpacity",
  "ringOffsetWidth",
  "ringOffsetColor",
  "blur",
  "brightness",
  "contrast",
  "dropShadow",
  "grayscale",
  "hueRotate",
  "invert",
  "saturate",
  "sepia",
  "filter",
  "backdropBlur",
  "backdropBrightness",
  "backdropContrast",
  "backdropGrayscale",
  "backdropHueRotate",
  "backdropInvert",
  "backdropOpacity",
  "backdropSaturate",
  "backdropSepia",
  "backdropFilter",
  "transitionProperty",
  "transitionDelay",
  "transitionDuration",
  "transitionTimingFunction",
  "willChange",
  "content",
  "forcedColorAdjust"
]