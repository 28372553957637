import { useState } from '#app'
const useFlowOry = () => {
  let currentDate = new Date()
  const kratosFlowId = useCookie<string|null|undefined>('kratosFlowId', { expires: new Date(currentDate.setHours(currentDate.getHours() + 1))})
  const kratosFlow = useState<any>('kratosFlow', () => null)

  const setFlowId = (flowId: string | null | undefined) => {
    kratosFlowId.value = flowId
  }

  const setKratosFlow = (flow: any) => {
    kratosFlow.value = flow
  }

  return {
    kratosFlowId,
    kratosFlow,
    setFlowId,
    setKratosFlow
  }
}
export default useFlowOry
