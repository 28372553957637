import { sidebarNavigation } from '@/routes'
const fetchUserAuthData = async () => {
  const { data, error } = await useRemApi<any>(`/users/me`);
  if (error.value) {
    return null;
  }
  return data.value;
}

const isPathNotAllowed = (to: any) => {
  const pathsNotAllowed = [
    'password',
    'recovery',
    'login',
    'verification',
    'failed'];
  return pathsNotAllowed.every(path => !to.path?.includes(path));
}

const shouldRedirect = (path: string) => {
  const pathsToCheck = [
    'recovery',
    'verification',
    'failed'
  ];
  return pathsToCheck.some(p => path?.includes(p));
}

export default defineNuxtRouteMiddleware(async (to, from) => {
  const config = useRuntimeConfig()

  try {
    if (process.server && config.public.developmentWithOry === 'true') return;
    // Get session
    const sessionData = (await useOryValidateSession())?.session


    if (!sessionData.value && !shouldRedirect(to.path) && !to.path?.includes('login')) {
      return navigateTo('/auth/login')
    }

    const { setUserAuthOryData } = useUserAuthOry()
    setUserAuthOryData(sessionData.value)

    if (isLinkRecoveryMethod(sessionData.value) && isPathNotAllowed(to)) {
      if (from.path === 'password') {
        return abortNavigation();
      }
      return navigateTo(config.public.redirectUrlToChangePassword, { external: true });
    }

    if (config.public.developmentWithOry === 'true') return;

    const userData = await fetchUserAuthData();

    const { setUserAuthData } = useUserAuth();
    setUserAuthData(userData);

    // If the route has no permits, allow navigation
    const matchedRoute = sidebarNavigation.find((nav) => to.path.includes(nav.href));
    if (!matchedRoute?.role) return; // Allow navigation



    // If the user is an admin, allow navigation or if the user has the same role as the route
    if (userData.user?.role === 'admin' || userData.user?.role === matchedRoute.role) {
      return; // Allow navigation
    }

    // Validate if the user tries to access a route that has no permissions from the URL, redirect A /WELCOME
    if (to.path === from.path) {
      return navigateTo('/welcome');
    }


    // If the user does not meet the required role, abort navigation
    return abortNavigation();




  } catch (error: any) {
    useBugsnag().notify(error)
    return navigateTo('/auth/login')
  }
})
