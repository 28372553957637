import { default as loginILQdBdZzRsMeta } from "/vercel/path0/pages/auth/login.vue?macro=true";
import { default as recoveryKWIC6ER9SyMeta } from "/vercel/path0/pages/auth/recovery.vue?macro=true";
import { default as verificationcd6PBCgVZgMeta } from "/vercel/path0/pages/auth/verification.vue?macro=true";
import { default as _2faLsqkhmxJD4Meta } from "/vercel/path0/pages/configuration/2fa.vue?macro=true";
import { default as passwordYVQzL2Xs09Meta } from "/vercel/path0/pages/configuration/password.vue?macro=true";
import { default as profile2OA6iPFAeRMeta } from "/vercel/path0/pages/configuration/profile.vue?macro=true";
import { default as failedNIdIlZANtlMeta } from "/vercel/path0/pages/failed.vue?macro=true";
import { default as indexOXtObJcgq9Meta } from "/vercel/path0/pages/users/index.vue?macro=true";
import { default as welcomelxppa1y2l8Meta } from "/vercel/path0/pages/welcome.vue?macro=true";
import { default as component_45stubUHgG5aOkzGMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
export default [
  {
    name: loginILQdBdZzRsMeta?.name ?? "auth-login",
    path: loginILQdBdZzRsMeta?.path ?? "/auth/login",
    meta: loginILQdBdZzRsMeta || {},
    alias: loginILQdBdZzRsMeta?.alias || [],
    redirect: loginILQdBdZzRsMeta?.redirect,
    component: () => import("/vercel/path0/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: recoveryKWIC6ER9SyMeta?.name ?? "auth-recovery",
    path: recoveryKWIC6ER9SyMeta?.path ?? "/auth/recovery",
    meta: recoveryKWIC6ER9SyMeta || {},
    alias: recoveryKWIC6ER9SyMeta?.alias || [],
    redirect: recoveryKWIC6ER9SyMeta?.redirect,
    component: () => import("/vercel/path0/pages/auth/recovery.vue").then(m => m.default || m)
  },
  {
    name: verificationcd6PBCgVZgMeta?.name ?? "auth-verification",
    path: verificationcd6PBCgVZgMeta?.path ?? "/auth/verification",
    meta: verificationcd6PBCgVZgMeta || {},
    alias: verificationcd6PBCgVZgMeta?.alias || [],
    redirect: verificationcd6PBCgVZgMeta?.redirect,
    component: () => import("/vercel/path0/pages/auth/verification.vue").then(m => m.default || m)
  },
  {
    name: _2faLsqkhmxJD4Meta?.name ?? "configuration-2fa",
    path: _2faLsqkhmxJD4Meta?.path ?? "/configuration/2fa",
    meta: _2faLsqkhmxJD4Meta || {},
    alias: _2faLsqkhmxJD4Meta?.alias || [],
    redirect: _2faLsqkhmxJD4Meta?.redirect,
    component: () => import("/vercel/path0/pages/configuration/2fa.vue").then(m => m.default || m)
  },
  {
    name: passwordYVQzL2Xs09Meta?.name ?? "configuration-password",
    path: passwordYVQzL2Xs09Meta?.path ?? "/configuration/password",
    meta: passwordYVQzL2Xs09Meta || {},
    alias: passwordYVQzL2Xs09Meta?.alias || [],
    redirect: passwordYVQzL2Xs09Meta?.redirect,
    component: () => import("/vercel/path0/pages/configuration/password.vue").then(m => m.default || m)
  },
  {
    name: profile2OA6iPFAeRMeta?.name ?? "configuration-profile",
    path: profile2OA6iPFAeRMeta?.path ?? "/configuration/profile",
    meta: profile2OA6iPFAeRMeta || {},
    alias: profile2OA6iPFAeRMeta?.alias || [],
    redirect: profile2OA6iPFAeRMeta?.redirect,
    component: () => import("/vercel/path0/pages/configuration/profile.vue").then(m => m.default || m)
  },
  {
    name: failedNIdIlZANtlMeta?.name ?? "failed",
    path: failedNIdIlZANtlMeta?.path ?? "/failed",
    meta: failedNIdIlZANtlMeta || {},
    alias: failedNIdIlZANtlMeta?.alias || [],
    redirect: failedNIdIlZANtlMeta?.redirect,
    component: () => import("/vercel/path0/pages/failed.vue").then(m => m.default || m)
  },
  {
    name: indexOXtObJcgq9Meta?.name ?? "users",
    path: indexOXtObJcgq9Meta?.path ?? "/users",
    meta: indexOXtObJcgq9Meta || {},
    alias: indexOXtObJcgq9Meta?.alias || [],
    redirect: indexOXtObJcgq9Meta?.redirect,
    component: () => import("/vercel/path0/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: welcomelxppa1y2l8Meta?.name ?? "welcome",
    path: welcomelxppa1y2l8Meta?.path ?? "/welcome",
    meta: welcomelxppa1y2l8Meta || {},
    alias: welcomelxppa1y2l8Meta?.alias || [],
    redirect: welcomelxppa1y2l8Meta?.redirect,
    component: () => import("/vercel/path0/pages/welcome.vue").then(m => m.default || m)
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: () => import("/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  }
]